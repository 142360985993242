var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"width":3,"color":"red","indeterminate":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_c('p',[_vm._v(_vm._s(_vm.$t("Checkout.wait")))])])],1)],1),_c('n-panels',{scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('v-expansion-panels',{staticStyle:{"z-index":"0"},attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":_vm.error == 'date'},scopedSlots:_vm._u([(_vm.error == 'date')?{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle-outline ")])]},proxy:true}:null],null,true)},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-package-variant-closed")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Checkout.deliveryDetails")))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('v-row',{staticClass:"py-4"},[_c('n-form',{ref:"form1"},[(_vm.isScheduleable)?_c('n-date-picker',{attrs:{"label":_vm.$t('Checkout.deliveryDate'),"rules":[_vm.$v.required, _vm.$v.futureOrEquals()],"cols":"6"},model:{value:(_vm.delivery.date),callback:function ($$v) {_vm.$set(_vm.delivery, "date", $$v)},expression:"delivery.date"}}):_vm._e(),(_vm.isScheduleable)?_c('n-time-picker',{attrs:{"label":_vm.$t('Checkout.deliveryTime'),"rules":[
                    _vm.$v.required,
                    _vm.$v.futureOrEquals(
                      null,
                      'YYYY-MM-DD HH:mm',
                      function (time) { return ((_vm.delivery.date) + " " + time); }
                    ) ],"cols":"6"},model:{value:(_vm.delivery.time),callback:function ($$v) {_vm.$set(_vm.delivery, "time", $$v)},expression:"delivery.time"}}):_vm._e(),_c('n-textarea',{attrs:{"label":_vm.$t('Checkout.deliveryInstructions'),"placeholder":_vm.$t('Checkout.deliveryInstructionsExample'),"cols":"12"},model:{value:(_vm.tblLgtOc.instrucciones),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "instrucciones", $$v)},expression:"tblLgtOc.instrucciones"}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":_vm.error == 'invoice'},scopedSlots:_vm._u([(_vm.error == 'invoice')?{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle-outline ")])]},proxy:true}:null],null,true)},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-note-text-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Checkout.billing')))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('v-row',{staticClass:"py-4"},[_c('n-form',{ref:"form2"},[_c('n-field',{attrs:{"rules":[_vm.$v.rfc],"label":"RFC","cols":"6"},model:{value:(_vm.tblLgtOc.rfc),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "rfc", $$v)},expression:"tblLgtOc.rfc"}}),_c('n-field',{attrs:{"label":"Nombre o razon social","rules":[_vm.$v.required],"cols":"6","disabled":_vm.tblLgtOc.rfc == undefined ||
                    _vm.tblLgtOc.rfc == null ||
                    _vm.tblLgtOc.rfc == ''},model:{value:(_vm.tblLgtOc.razonSocial),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "razonSocial", $$v)},expression:"tblLgtOc.razonSocial"}}),_c('n-field',{attrs:{"label":"Correo electronico","rules":[_vm.$v.required, _vm.$v.email],"cols":"6","disabled":_vm.tblLgtOc.rfc == undefined ||
                    _vm.tblLgtOc.rfc == null ||
                    _vm.tblLgtOc.rfc == ''},model:{value:(_vm.tblLgtOc.invoiceEmail),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "invoiceEmail", $$v)},expression:"tblLgtOc.invoiceEmail"}}),_c('n-field',{attrs:{"label":"Codigo postal","rules":[_vm.$v.required],"cols":"6","disabled":_vm.tblLgtOc.rfc == undefined ||
                    _vm.tblLgtOc.rfc == null ||
                    _vm.tblLgtOc.rfc == ''},model:{value:(_vm.tblLgtOc.cp),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "cp", $$v)},expression:"tblLgtOc.cp"}}),_c('n-choice',{attrs:{"label":"Regimen fiscal","source":"api/v1/public/fct/regimenesfiscal","item-text":"label","item-value":"valor","return-object":false,"rules":[_vm.$v.required],"cols":"6","disabled":_vm.tblLgtOc.rfc == undefined ||
                    _vm.tblLgtOc.rfc == null ||
                    _vm.tblLgtOc.rfc == ''},model:{value:(_vm.tblLgtOc.regimenFiscalId),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "regimenFiscalId", $$v)},expression:"tblLgtOc.regimenFiscalId"}}),_c('n-choice',{attrs:{"label":"Uso CFDI","source":"api/v1/public/fct/usoscfdi","item-text":"label","item-value":"valor","return-object":false,"rules":[_vm.$v.required],"cols":"6","disabled":_vm.tblLgtOc.rfc == undefined ||
                    _vm.tblLgtOc.rfc == null ||
                    _vm.tblLgtOc.rfc == ''},model:{value:(_vm.tblLgtOc.usoCfdi),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "usoCfdi", $$v)},expression:"tblLgtOc.usoCfdi"}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":_vm.error == 'address'},scopedSlots:_vm._u([(_vm.error == 'address')?{key:"actions",fn:function(){return [_c('v-subheader',{staticClass:"error--text"},[_vm._v(" Debe seleccionar una direccion ")]),_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle-outline ")])]},proxy:true}:null],null,true)},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-map-marker-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(("" + (_vm.$t("Checkout.deliveryAddress")))))])],1)],1),_c('v-expansion-panel-content',[_c('v-item-group',{staticStyle:{"width":"100%"},attrs:{"mandatory":""},model:{value:(_vm.tblLgtOc.tblCnfUbicacion),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "tblCnfUbicacion", $$v)},expression:"tblLgtOc.tblCnfUbicacion"}},[_c('v-container',[_c('v-row',[_vm._l((_vm.addresses),function(address,index){return _c('v-col',{key:index,attrs:{"cols":"12","xl":"3","lg":"3"}},[_c('v-item',{attrs:{"value":address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'grey lighten-2' : 'white',"height":"100","flat":"","disabled":_vm.itemsCount < 1},on:{"click":toggle}},[_c('v-card-text',[_c('div',{staticClass:"caption",staticStyle:{"word-wrap":"break-word","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"3","line-clamp":"3","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(address.ubicacion)+" ")])])],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"height":"100"},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account/add-address")).catch(function () {}); }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")])],1)],1)],2)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":_vm.error == 'contact'},scopedSlots:_vm._u([(_vm.error == 'contact')?{key:"actions",fn:function(){return [_c('v-subheader',{staticClass:"error--text"},[_vm._v(" Debe seleccionar un contacto ")]),_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle-outline ")])]},proxy:true}:null],null,true)},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-box-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(((_vm.$t("Checkout.contactInformation")) + " (Required)")))])],1)],1),_c('v-expansion-panel-content',[_c('v-item-group',{staticStyle:{"width":"100%"},attrs:{"mandatory":""},model:{value:(_vm.tblLgtOc.tblCnfCuentaContacto),callback:function ($$v) {_vm.$set(_vm.tblLgtOc, "tblCnfCuentaContacto", $$v)},expression:"tblLgtOc.tblCnfCuentaContacto"}},[_c('v-container',[_c('v-row',[_vm._l((_vm.contacts),function(contact,index){return _c('v-col',{key:'c' + index,attrs:{"cols":"12","xl":"3","lg":"3"}},[_c('v-item',{attrs:{"value":contact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'grey lighten-2' : 'white',"height":"100","disabled":_vm.itemsCount < 1},on:{"click":toggle}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-truncate caption",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(contact.nombreContacto)),_c('br'),_vm._v(" "+_vm._s(contact.telefono)),_c('br'),_vm._v(" "+_vm._s(contact.email)+" ")])],1)],1)],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"height":"100"},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account/add-contact")).catch(function () {}); }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")])],1)],1)],2)],1)],1)],1)],1)],1),_c('div',{staticStyle:{"width":"100%","height":"200px"}})]},proxy:true},{key:"right",fn:function(){return [_c('order-details',{attrs:{"items":_vm.items,"editable":false,"price-key":"costoSinIva"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var count = ref.count;
return [_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function () { return _vm.generateOrder(); }}},[_vm._v(" "+_vm._s(_vm.$t("Checkout.placeOrder"))+" ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }