<template>
  <v-container class="">
    <v-overlay :value="loading" >
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :width="3"
            color="red"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="font-weight-bold">
          <p>{{ $t("Checkout.wait") }}</p>
        </v-col>
      </v-row>
    </v-overlay>

    <n-panels>

      <template #center>
        <v-expansion-panels multiple v-model="panels" style="z-index:0">
          <v-expansion-panel>
            <v-expansion-panel-header :disable-icon-rotate="error == 'date'">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-package-variant-closed</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $t("Checkout.deliveryDetails")
                }}</v-list-item-title>
              </v-list-item>

              <template v-slot:actions v-if="error == 'date'">
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content eager>
              <v-row class="py-4">
                <n-form ref="form1">
                  <n-date-picker
                    v-if="isScheduleable"
                    v-model="delivery.date"
                    :label="$t('Checkout.deliveryDate')"
                    :rules="[$v.required, $v.futureOrEquals()]"
                    cols="6"
                  ></n-date-picker>

                  <n-time-picker
                    v-if="isScheduleable"
                    v-model="delivery.time"
                    :label="$t('Checkout.deliveryTime')"
                    :rules="[
                      $v.required,
                      $v.futureOrEquals(
                        null,
                        'YYYY-MM-DD HH:mm',
                        (time) => `${delivery.date} ${time}`
                      ),
                    ]"
                    cols="6"
                  ></n-time-picker>

                  <n-textarea
                    v-model="tblLgtOc.instrucciones"
                    :label="$t('Checkout.deliveryInstructions')"
                    :placeholder="$t('Checkout.deliveryInstructionsExample')"
                    cols="12"
                  ></n-textarea>
                </n-form>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              :disable-icon-rotate="error == 'invoice'"
            >
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-note-text-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  >{{ $t('Checkout.billing') }}</v-list-item-title
                >
              </v-list-item>

              <template v-slot:actions v-if="error == 'invoice'">
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content eager>
              <v-row class="py-4">
                <n-form ref="form2">
                  <n-field
                    v-model="tblLgtOc.rfc"
                    :rules="[$v.rfc]"
                    label="RFC"
                    cols="6"
                  ></n-field>

                  <n-field
                    v-model="tblLgtOc.razonSocial"
                    label="Nombre o razon social"
                    :rules="[$v.required]"
                    cols="6"
                    :disabled="
                      tblLgtOc.rfc == undefined ||
                      tblLgtOc.rfc == null ||
                      tblLgtOc.rfc == ''
                    "
                  ></n-field>

                  <n-field
                    v-model="tblLgtOc.invoiceEmail"
                    label="Correo electronico"
                    :rules="[$v.required, $v.email]"
                    cols="6"
                    :disabled="
                      tblLgtOc.rfc == undefined ||
                      tblLgtOc.rfc == null ||
                      tblLgtOc.rfc == ''
                    "
                  ></n-field>

                  <n-field
                    v-model="tblLgtOc.cp"
                    label="Codigo postal"
                    :rules="[$v.required]"
                    cols="6"
                    :disabled="
                      tblLgtOc.rfc == undefined ||
                      tblLgtOc.rfc == null ||
                      tblLgtOc.rfc == ''
                    "
                  ></n-field>

                  <n-choice
                    v-model="tblLgtOc.regimenFiscalId"
                    label="Regimen fiscal"
                    source="api/v1/public/fct/regimenesfiscal"
                    item-text="label"
                    item-value="valor"
                    :return-object="false"
                    :rules="[$v.required]"
                    cols="6"
                    :disabled="
                      tblLgtOc.rfc == undefined ||
                      tblLgtOc.rfc == null ||
                      tblLgtOc.rfc == ''
                    "
                  ></n-choice>

                  <n-choice
                    v-model="tblLgtOc.usoCfdi"
                    label="Uso CFDI"
                    source="api/v1/public/fct/usoscfdi"
                    item-text="label"
                    item-value="valor"
                    :return-object="false"
                    :rules="[$v.required]"
                    cols="6"
                    :disabled="
                      tblLgtOc.rfc == undefined ||
                      tblLgtOc.rfc == null ||
                      tblLgtOc.rfc == ''
                    "
                  ></n-choice>
                </n-form>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel >
            <v-expansion-panel-header
              :disable-icon-rotate="error == 'address'"
            >
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-map-marker-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  `${$t("Checkout.deliveryAddress")}`
                }}</v-list-item-title>
              </v-list-item>

              <template v-slot:actions v-if="error == 'address'">
                <v-subheader class="error--text">
                  Debe seleccionar una direccion
                </v-subheader>
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-item-group
                v-model="tblLgtOc.tblCnfUbicacion"
                mandatory
                style="width: 100%"
              >
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(address, index) in addresses"
                      :key="index"
                      cols="12"
                      xl="3"
                      lg="3"
                    >
                      <v-item v-slot="{ active, toggle }" :value="address">
                        <v-card
                          :color="active ? 'grey lighten-2' : 'white'"
                          class="d-flex align-center"
                          height="100"
                          flat
                          @click="toggle"
                          :disabled="itemsCount < 1"
                        >
                          <v-card-text>
                            <div
                              class="caption"
                              style="
                                word-wrap: break-word;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                line-clamp: 3;
                                -webkit-box-orient: vertical;
                              "
                            >
                              {{ address.ubicacion }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-item>
                    </v-col>

                    <v-col cols="12" xl="3" lg="3">
                      <v-card
                        class="d-flex align-center justify-center"
                        height="100"
                        @click="() => $router.push(`/entities/${$route.params.entidadId}/account/add-address`).catch(() => {})"
                      >
                        <v-icon large>mdi-plus</v-icon>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              :disable-icon-rotate="error == 'contact'"
            >
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  `${$t("Checkout.contactInformation")} (Required)`
                }}</v-list-item-title>
              </v-list-item>

              <template v-slot:actions v-if="error == 'contact'">
                <v-subheader class="error--text">
                  Debe seleccionar un contacto
                </v-subheader>
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-item-group
                v-model="tblLgtOc.tblCnfCuentaContacto"
                mandatory
                style="width: 100%"
              >
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(contact, index) in contacts"
                      :key="'c' + index"
                      cols="12"
                      xl="3"
                      lg="3"
                    >
                      <v-item v-slot="{ active, toggle }" :value="contact">
                        <v-card
                          :color="active ? 'grey lighten-2' : 'white'"
                          class="d-flex align-center"
                          height="100"
                          @click="toggle"
                          :disabled="itemsCount < 1"
                        >
                          <v-card-text>
                            <v-row justify="center" align="center">
                              <v-col cols="12" class="text-truncate caption">
                                {{ contact.nombreContacto }}<br />
                                {{ contact.telefono }}<br />
                                {{ contact.email }}
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-item>
                    </v-col>

                    <v-col cols="12" xl="3" lg="3">
                      <v-card
                        class="d-flex align-center justify-center"
                        height="100"
                        @click="() => $router.push(`/entities/${$route.params.entidadId}/account/add-contact`).catch(() => {})"
                      >
                        <v-icon large>mdi-plus</v-icon>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div style="width: 100%; height: 200px;"></div>
      </template>

      <template #right>
        <order-details
          :items="items"
          :editable="false"
          price-key="costoSinIva"
        >
          <template #actions="{ count }">
            <v-btn
              color="primary"
              class=""
              block
              @click="() => generateOrder()"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("Checkout.placeOrder") }}
            </v-btn>
          </template>
        </order-details>
      </template>

    </n-panels>
  </v-container>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import NPanels from '../../components/newgen/NPanels.vue';
import OrderDetails from "../../components/OrderDetails.vue";

export default {
  components: {
    NPanels,
    OrderDetails,
  },
  watch: {
    "$route.params.entidadId": {
      immediate: true,
      handler() {
        // SE OBTIENE LA INFORMACION DE LA ORDEN DE COMPRA AL CAMBIAR EN VALOR DE LA ENTIDAD
        this.fetchServiceOrderDetails();
      },
    },
  },
  computed: {
    itemsCount() {
      // SE OBTIENE EL CONTEO DE ITEMS EN EL CARRITO DE COMPRAS
      return this.items.length;
    },
  },
  data() {
    return {
      items: [],
      tblLgtOc: {},
      delivery: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().add(1, "hours").format("HH:mm"),
      },
      addresses: [],
      contacts: [],
      loading: false,
      panels: [0, 3],
      error: null,
      isScheduleable: false,
      //freeAddress:false
    };
  },
  mounted() {
    // SE OBTIENEN LAS DIRECCIONES REGISTRADAS DEL USUARIO EN SESION
    this.getAddresses();

    // SE OBTIENEN CONTACTOS REGISTRADOS DEL USUARIO EN SESION
    this.getContacts();
  },
  methods: {
    checkIsScheduleable(){
      this.isScheduleable = this.items.some(item => item.esAgendable ==='N');
      /*if(this.isScheduleable == false){
        this.freeAddress = this.items.some(item => item.esAgendable ==='Y')
      }*/
    },
    getAddresses() {
      axios
        .get("api/v1/private/addresses")
        .then((res) => {
          const addresses = _.get(res, "data.data", []);

          this.addresses = addresses;
        })
        .catch(() => {
          this.addresses = [];
        });
    },
    getContacts() {
      axios
        .get("api/v1/private/contacts")
        .then((res) => {
          const contacts = _.get(res, "data.data", []);

          this.contacts = contacts;
        })
        .catch(() => {
          // DOES NOTHING
        });
    },
    fetchServiceOrderDetails() {
      const params = this.$route.params;
      const entidadId = params.entidadId;
      const comercioId = params.comercioId;

      this.loading = true;

      axios
        .get(`api/v1/entities/${entidadId}/commerces/${comercioId}/cart`)
        .then((res) => {
          const items = _.get(res, "data.data", []);

          this.items = items;
          // SE LLAMA A METODO QUE COMPRUEBA SI HAY ALGUN PRODUCTO AGENDABLE
          this.checkIsScheduleable();
        })
        .catch(() => {
          this.items = [];
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    validation() {
      // SE REINICIA EL INDICADOR DE ERRORES
      this.error = null;

      // SE VALIDA EL FORMULARIO DE FECHA
      this.$refs.form1
        .validate()
        .then(() => {
          // SE VALIDA EL FORMULARIO DE FACTURACION
          this.$refs.form2
            .validate()
            .then(() => {
              // SE VALIDA LA SELECCION DE DIRECCION DE ENTREGA
              /*if(!this.freeAddress){
                if (_.isEmpty(this.tblLgtOc.tblCnfUbicacion)) {
                this.error = "address";
                }
              }
              else {*/
                // SE VALIDA LA SELECCION DE INFORMACION DE CONTACTO
                if (_.isEmpty(this.tblLgtOc.tblCnfCuentaContacto)) {
                  this.error = "contact";
                } else {
                  this.generateOrder();
                //}
              }
            })
            .catch(() => {
              this.error = "invoice";
            });
        })
        .catch(() => {
          this.error = "date";
        });
    },
    generateOrder() {
      let date = this.delivery.date;
      let time = this.delivery.time;

      _.set(this.tblLgtOc, "fechaVigenciaIni", date);
      _.set(this.tblLgtOc, "fechaVigenciaFin", date);
      _.set(this.tblLgtOc, "fechaOc", `${date} ${time}`);

      let params = this.$route.params;
      const entidadId = params.entidadId;
      const comercioId = params.comercioId;

      this.loading = true;

      axios
        .post(`api/v1/entities/${entidadId}/commerces/${comercioId}/orders`, this.tblLgtOc)
        .then((res) => {
          this.loading = false;

          this.$store.dispatch("cart/getCart");

          // SE OBTIENE LA INFORMACION DE LA ORDEN DE COMPRA
          let order = _.get(res, "data.data", {});

          this.$router.push(`/orders/${order.ocId}/payments`).catch(() => {});
        })
        .catch(() => {
          this.loading = false;

          this.$dialog({
            title: this.$t("dialog.error"),
            text: "Hubo un error al intentar realizar la compra, por favor intentelo de nuevo",
          });
        });
    },
  },
};
</script>